import React from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';

import layoutstyles from './layout/Layout.module.css';


export class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.doLogin = this.doLogin.bind(this);
    }

    doLogin() {
        this.props.msalInstance.loginRedirect();
    };

    render() {
        return (<Container>
            <Row>
                <Col xs="12" md="8" xl="6" className={"mx-auto"}>
                    <br /><br /><br />
                    <Jumbotron className={"text-center"}>
                        <img src="images/Karger_Green.png" alt="Logo Karger Publishers" className={layoutstyles.logo_login} />
                        <h1>Production Web</h1>
                        <p>Please log-in with your User Account.</p>
                        <p>
                            <span className={"btn btn-primary"} onClick={this.doLogin}>Login</span>
                        </p>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>);
    };
}