import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Navigation } from './Navigation';
import layoutstyles from './Layout.module.css';

export class Header extends React.Component {
    state = {
        isOpen: false
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return (
            <div>
                <Navbar>
                    <Container fluid={true} className="justify-content-start">
                        <a href="https://www.karger.com"><img src="images/Karger_White.png" alt="Logo Karger Publishers" className={layoutstyles.logo_nav} /></a>
                        <NavbarBrand href="/">Production Web<sup>beta</sup></NavbarBrand>
                    </Container>
                    {this.props.showNavigation ? <Navigation/> : <></> }
                </Navbar>
            </div>
        );
    };
}

Header.defaultProps = {
    showNavigation: true
}