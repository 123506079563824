import React from 'react';
import { Spinner } from 'reactstrap';

export class JATSXmlUploadProcess extends React.Component {
    //state = {
    //    file: {},
    //    fileLink: '',
    //    fileName: '',
    //    tables: 0,
    //    appendix: 0,
    //    isUploading: false,
    //    inputValue: '',
    //    error: ''
    //};

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            fileLink: '',
            fileName: '',
            tables: 0,
            appendix: 0,
            isUploading: false,
            inputValue: '',
            error: ''
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
    }

    onFormSubmit(e) {
        e.preventDefault(); // Stop form submit
        this.setState({ isUploading: true, fileLink: "", tables: 0, appendix: 0, fileName: "", error: "" });
        this.fileUpload(this.state.file)
            .then((response) => {
                response.json().then((res) => {
                    if (res.success) {
                        this.setState({ isUploading: false, fileLink: "/Temp/" + res.fileName, fileName: res.fileName, tables: res.tables, appendix: res.appendix, inputValue: '' });
                    } else {
                        this.setState({isUploading: false, fileLink: "", fileName: "", tables: 0, appendix: 0, error: "Error in convert process : " + res.error + "<br/>" + res.innererror  });
                    }
                });
            }).catch((error) => {
                console.log(error);
                this.setState({ isUploading: false, fileLink: "", fileName: "", tables: 0, appendix: 0, error: "Error in convert process" + error });
            });
    }
    onChange(e) {
        this.setState({ file: e.target.files[0], inputValue: e.target.value });
    }
    fileUpload(file) {
        const url = '/xml';
        const formData = new FormData();
        formData.append('uploadfile', file, file.name);
        return fetch(url, {
            method: 'POST',
            body: formData
        });
    }

    render() {
        return (            
            <form onSubmit={this.onFormSubmit}>
                <div className={"card"}>
                    <div className={"card-header"}>
                        <h6 className={"my-0"}><strong>JATS to FlatXML Converter</strong></h6>
                    </div>
                    <div className={"card-body"}>
                        <input type="file" accept=".xml" onChange={this.onChange} value={this.state.inputValue} /><br />
                        {this.state.isUploading ?
                            <Spinner type="grow" style={{ color: '#004b4f' }} className={"mt-5"} /> :
                            <button type="submit" className={"mt-5"} disabled={this.state.inputValue === '' ? "disabled" : "" }>Upload</button>}
                        <br />
                        {this.state.fileLink !== '' ? <a href={this.state.fileLink} target="_blank" rel="noopener noreferrer" download={this.state.fileName} className={"btn btn-secondary mt-4"}>Download file</a> : <></>}
                        {this.state.tables > 0 ? <JATSXmlUploadProcessTable tables={this.state.tables} fileName={this.state.fileLink} /> : <></>}
                        {this.state.appendix > 0 ? <JATSXmlUploadProcessAppendix tables={this.state.appendix} fileName={this.state.fileLink} /> : <></>}
                        {this.state.error !== '' ? <p className={"text-danger mt-4"}>{this.state.error}</p> : <></>}
                    </div>
                </div>
            </form>            
        )
    }
}

class JATSXmlUploadProcessTable extends React.Component {
    render() {
        const tables = []

        for (var i = 1; i <= this.props.tables; i++) {
            tables.push(<a key={"table_"+i} className="tableLink" href={this.props.fileName.replace('.xml', "_t" + (i < 10 ? '0' + i : i)+'.xml')} rel="noopener noreferrer" target="_blank">Download Table {i}</a>);
        }

        return (
            <div>
                <strong>Tables</strong><br />
                {tables}
            </div>    
        );
    }
}

class JATSXmlUploadProcessAppendix extends React.Component {
    render() {
        const tables = []

        for (var i = 1; i <= this.props.tables; i++) {
            tables.push(<a key={"table_" + i} className="tableLink" href={this.props.fileName.replace('.xml', "_e" + (i < 10 ? '0' + i : i) + '.xml')} rel="noopener noreferrer" target="_blank">Download Appendix {i}</a>);
        }

        return (
            <div>
                <strong>Appendix Tables</strong><br />
                {tables}
            </div>
        );
    }
}