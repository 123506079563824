import React from 'react';
import { Row, Col } from 'reactstrap';

import { graphMe } from '../msal/msalHelper';
import { loginRequest } from '../msal/msalConfig';

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { ErrorPage } from './ErrorPage';
import { LoadingPage } from './LoadingPage';
import { msalInstance } from '../index';

import { JATSXmlUploadProcess } from './JATSXmlUploadProcess';
import { ImportUploadProcess } from './ImportUploadProcess';

import layoutstyles from './layout/Layout.module.css';


export class HomePage extends React.Component {
    state = {
        apiResponse: {}
    };

    componentDidMount() {
        graphMe(msalInstance)
            .then((response) => { this.setState({ apiResponse: response }); })
            .catch((error) => { console.error(error); });
    }

    render() {
        const authRequest = {
            ...loginRequest
        };

        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorPage}
                loadingComponent={LoadingPage}>
                <div className={ layoutstyles.maincontent }>
                    <Row>
                        <Col xs="12" xl="9">
                            <h2>Welcome {this.state.apiResponse.displayName}</h2>
                            {/*<p>{JSON.stringify(this.state.apiResponse, null, 2)}</p>*/}                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" xl="9">
                            <h3 className={"mb-4"}>Current Services</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" md="8" xl="4">
                            <JATSXmlUploadProcess />
                        </Col>
                        <Col xs="12" md="8" xl="4">
                            <ImportUploadProcess />
                        </Col>
                    </Row>
                </div>
            </MsalAuthenticationTemplate>
        );
    }
}
