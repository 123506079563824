import React from 'react';
import { Alert, Progress } from 'reactstrap';

export class ImportUploadProcess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            flatXmlFile: null,
            inDesignFile: null,
            figuresFile: null,
            pdfFile: null,
            inputXmlValue: '',
            inputInDesignValue: '',
            inputPDFValue: '',
            inputFiguresValue: '',
            articleNr: 0,
            isUploading: false,
            progressPercent: 0,
            progressText: '',
            error: '',
            showSuccess: false,
            showMessage: false,
            showMessageText: ''
        }
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChangeXml = this.onChangeXml.bind(this);
        this.onChangeInDesign = this.onChangeInDesign.bind(this);
        this.onChangePDF = this.onChangePDF.bind(this);
        this.onChangeFigures = this.onChangeFigures.bind(this);
        this.uploadAPI = this.uploadAPI.bind(this);
        this.resetAlert = this.resetAlert.bind(this);
    }

    acceptUpload() {
        return (this.state.articleNr > 0 && this.state.inputXmlValue !== '' && this.state.inputInDesignValue !== '' && this.state.inputPDFValue !== '');
    }

    // FORM SUBMIT
    onFormSubmit(e) {
        e.preventDefault() // Stop form submit
        this.setState({ isUploading: true, progressPercent: 1, progressText: 'Import FlatXML file' });
        this.uploadAPI('flatxml', this.state.flatXmlFile, this.state.articleNr, false, true)
            .then((resFlatXml) => {
                resFlatXml.json().then((resFlatXmlJson) => {
                    if (resFlatXmlJson.success) {

                        this.setState({ progressPercent: 2, progressText: 'Import InDesign file' });
                        this.uploadAPI('indesign', this.state.inDesignFile, this.state.articleNr, false, true)
                            .then((resInDesign) => {
                                resInDesign.json().then((resInDesignJson) => {
                                    if (resInDesignJson.success) {

                                        if (this.state.inputFiguresValue !== '') {
                                            this.setState({ progressPercent: 3, progressText: 'Import Figure files' });
                                            this.uploadAPI('figures', this.state.figuresFile, this.state.articleNr, true, true)
                                                .then((resFigures) => {
                                                    resFigures.json().then((resFiguresJson) => {
                                                        if (resFiguresJson.success) {

                                                            this.setState({ progressPercent: 4, progressText: 'Import PDF file' });
                                                            this.uploadAPI('pdf', this.state.pdfFile, this.state.articleNr, false, true)
                                                                .then((resPDF) => {
                                                                    resPDF.json().then((resPDFJson) => {
                                                                        if (resPDFJson.success) {


                                                                            this.setState({ progressPercent: 5, progressText: 'Finish Upload Process' });
                                                                            this.uploadAPI('finishupload', "", this.state.articleNr, false, false)
                                                                                .then((resFinish) => {
                                                                                    resFinish.json().then((resFinishJson) => {
                                                                                        if (resFinishJson.success) {
                                                                                            this.setState({ isUploading: false, error: '', progressPercent: 0, progressText: '', flatXmlFile: null, inDesignFile: null, figuresFile: null, inputXmlValue: '', inputInDesignValue: '', inputFiguresValue: '', pdfFile: null, inputPDFValue: '' });
                                                                                            this.showSuccess();
                                                                                        } else {
                                                                                            this.setState({ isUploading: false, error: "Error in Finish Upload process : " + resFinishJson.error });
                                                                                        }
                                                                                    });
                                                                                }).catch((error) => {
                                                                                    console.log(error);
                                                                                    this.setState({ isUploading: false, error: "Error in Finish Upload process" + error });
                                                                                });


                                                                        } else {
                                                                            this.setState({ isUploading: false, error: "Error in PDF process : " + resPDFJson.error });
                                                                        }
                                                                    });
                                                                }).catch((error) => {
                                                                    console.log(error);
                                                                    this.setState({ isUploading: false, error: "Error in PDF process" + error });
                                                                });


                                                        } else {
                                                            this.setState({ isUploading: false, error: "Error in Figures process : " + resFiguresJson.error });
                                                        }
                                                    });
                                                }).catch((error) => {
                                                    console.log(error);
                                                    this.setState({ isUploading: false, error: "Error in Figures process" + error });
                                                });

                                        } else { // WITHOUT FIGURES!
                                            this.setState({ progressPercent: 4, progressText: 'Import PDF file' });
                                            this.uploadAPI('pdf', this.state.pdfFile, this.state.articleNr, false, true)
                                                .then((resPDF) => {
                                                    resPDF.json().then((resPDFJson) => {
                                                        if (resPDFJson.success) {


                                                            this.setState({ progressPercent: 5, progressText: 'Finish Upload Process' });
                                                            this.uploadAPI('finishupload', "", this.state.articleNr, false, false)
                                                                .then((resFinish) => {
                                                                    resFinish.json().then((resFinishJson) => {
                                                                        if (resFinishJson.success) {
                                                                            this.setState({ isUploading: false, error: '', progressPercent: 0, progressText: '', flatXmlFile: null, inDesignFile: null, figuresFile: null, inputXmlValue: '', inputInDesignValue: '', inputFiguresValue: '', pdfFile: null, inputPDFValue: '' });
                                                                            this.showSuccess();
                                                                        } else {
                                                                            this.setState({ isUploading: false, error: "Error in Finish Upload process : " + resFinishJson.error });
                                                                        }
                                                                    });
                                                                }).catch((error) => {
                                                                    console.log(error);
                                                                    this.setState({ isUploading: false, error: "Error in Finish Upload process" + error });
                                                                });


                                                        } else {
                                                            this.setState({ isUploading: false, error: "Error in PDF process : " + resPDFJson.error });
                                                        }
                                                    });
                                                }).catch((error) => {
                                                    console.log(error);
                                                    this.setState({ isUploading: false, error: "Error in PDF process" + error });
                                                });


                                        }
                                    } else {
                                        this.setState({ isUploading: false, error: "Error in InDesign process : " + resInDesignJson.error });
                                    }
                                });
                            }).catch((error) => {
                                console.log(error);
                                this.setState({ isUploading: false, error: "Error in InDesign process" + error });
                            });

                    } else {
                        this.setState({ isUploading: false, error: "Error in FlatXML process : " + resFlatXmlJson.error });
                    }
                });
            }).catch((error) => {
                console.log(error);
                this.setState({ isUploading: false, error: "Error in FlatXML process" + error });
            });
    }

    // ALERT FUNCTIONS
    resetAlert() {
        this.setState({ showMessage: false, showMessageText: '' });
    }
    showUploadError(msg) {
        this.setState({ showMessage: true, showMessageText: msg }, () => {
            window.setTimeout(() => { this.resetAlert(); }, 10000)
        });
    }
    showSuccess() {
        this.setState({ showSuccess: true }, () => {
            window.setTimeout(() => { this.setState({ showSuccess: false }); }, 8000)
        });
    }

    // FILE INPUT CHANGE EVENTS
    onChangeXml(e) {
        let fileName = e.target.files[0].name;
        if (fileName.indexOf('.xml') !== -1) {
            fileName = fileName.substring(0, fileName.length - 4);
            if (fileName.length === 9 && !isNaN(fileName.substring(3, 9))) {
                this.setState({ showSuccess: false, flatXmlFile: e.target.files[0], inputXmlValue: e.target.value, articleNr: parseInt(fileName.substring(3, 9), 10) });
            } else if (fileName.length === 10 && !isNaN(fileName.substring(4, 10))) {
                this.setState({ showSuccess: false, flatXmlFile: e.target.files[0], inputXmlValue: e.target.value, articleNr: parseInt(fileName.substring(4, 10), 10) });
            } else {
                this.setState({ showSuccess: false, flatXmlFile: null, inputXmlValue: '' });
                this.showUploadError('FlatXML must named CODE3+ARTICLENR or CODE3-ARTICLENR | exp: ABC123456.xml');
            }
        } else {
            this.showUploadError('FlatXML must be an XML file');
        }
    }
    onChangeInDesign(e) {
        let fileName = e.target.files[0].name;
        if (fileName.indexOf('.indd') !== -1) {
            this.setState({ showSuccess: false, inDesignFile: e.target.files[0], inputInDesignValue: e.target.value });
        } else {
            this.setState({ showSuccess: false, inDesignFile: null, inputInDesignValue: '' });
            this.showUploadError('File must be an InDesign (.indd) file');
        }
    }
    onChangePDF(e) {
        let fileName = e.target.files[0].name;
        if (fileName.indexOf('.pdf') !== -1) {
            this.setState({ showSuccess: false, pdfFile: e.target.files[0], inputPDFValue: e.target.value });
        } else {
            this.setState({ showSuccess: false, pdfFile: null, inputPDFValue: '' });
            this.showUploadError('File must be an PDF (.pdf) file');
        }
    }
    onChangeFigures(e) {
        this.setState({ showSuccess: false, figuresFile: e.target.files, inputFiguresValue: e.target.value });
    }

    // API CONNECTION
    uploadAPI(functionName, fileObj, articleNr, isMulti, hasFiles) {
        const url = '/import/' + functionName;
        const formData = new FormData();
        formData.append('articleNr', articleNr);

        if (functionName === 'flatxml') {
            formData.append('hasFigures', this.state.inputFiguresValue !== '');
        }

        if (hasFiles) {
            if (isMulti) {
                for (const file of fileObj) {
                    formData.append('uploadfiles[]', file, file.name);
                }
            } else {
                formData.append('uploadfile', fileObj, fileObj.name);
            }
        }

        return fetch(url, {
            method: 'POST',
            body: formData
        });
    }


    // RENDERING
    render() {
        return (
            <form onSubmit={this.onFormSubmit}>
                <Alert color="warning" isOpen={this.state.showMessage} toggle={this.resetAlert}>{this.state.showMessageText}</Alert>
                <div className={"card"}>
                    <div className={"card-header"}>
                        <h6 className={"my-0"}><strong>Import Article into Karger</strong></h6>
                    </div>
                    <div className={"card-body"}>
                        <label>
                            FlatXML *<br />
                            <input id="flatXML" accept=".xml" type="file" onChange={this.onChangeXml} value={this.state.inputXmlValue} /><br />
                        </label>
                        <label>
                            InDesign *<br />
                            <input id="indesign" accept=".indd" type="file" onChange={this.onChangeInDesign} value={this.state.inputInDesignValue} /><br />
                        </label>
                        <label>
                            Figures<br />
                            <input id="images" multiple accept=".jpg, .jpeg, .png, .ai, .psd" type="file" onChange={this.onChangeFigures} value={this.state.inputFiguresValue} /><br />
                        </label>
                        <label>
                            PDF *<br />
                            <input id="pdf" accept=".pdf" type="file" onChange={this.onChangePDF} value={this.state.inputPDFValue} /><br />
                        </label>
                        <br />
                        <br />
                        <span>* = Required</span>
                        <br />
                        {this.state.isUploading ?
                            <div>
                                <Progress animated value={this.state.progressPercent} max="6">{this.state.progressText}</Progress>
                            </div> :
                            <button type="submit" className={"mt-5"} disabled={this.acceptUpload() ? "" : "disabled"}>Upload</button>}
                        <br />
                        {this.state.showSuccess ? <p className={"text-success"}>Sucessfully uploaded.</p> : <></>}
                        {this.state.error !== '' ? <p className={"text-danger mt-4"}>{this.state.error}</p> : <></>}
                    </div>
                </div>
            </form>
        );
    }
}