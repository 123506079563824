import React from 'react';
import { Container } from 'reactstrap';

import { loginRequest } from "../../msal/msalConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { Header } from './Header';
import { Footer } from './Footer';

import { ErrorPage } from '../ErrorPage';
import { LoadingPage } from '../LoadingPage';


export class Layout extends React.Component {
    render() {
        const authRequest = {
            ...loginRequest
        };

        return (
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorPage}
                loadingComponent={LoadingPage}
            >
                <Container fluid={true}>
                    <Header showNavigation={this.props.showNavigation} />
                    <this.props.componentToPass/>
                    <Footer />
                </Container>
            </MsalAuthenticationTemplate>
        );
    }
}
Layout.defaultProps = {
    showNavigation: true,
    componentToPass: <></>
}