import React from 'react';

export class Footer extends React.Component {
    render() {
        return (
            <footer>
                <p className={"text-right"}>&copy; 2021 S. Karger AG, Basel</p>
            </footer>
        );
    };
}