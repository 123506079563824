import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Jumbotron } from 'reactstrap';

export class ErrorPage extends React.Component {
    render() {
        console.log(this.props.error);
        return (
            <Container>
                <Row>
                    <Col xs="12">
                        <br /><br /><br />
                        <Jumbotron>
                            <h2>Error occurs</h2>
                            <p>
                                <strong>{this.props.error.errorCode}</strong>
                            </p>
                            <p><Link to="/">Back</Link></p>
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    };
}