import React from 'react';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';

import { msalInstance } from '../../index';

import layoutstyles from './Layout.module.css';


export class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.doLogout = this.doLogout.bind(this);
    }

    doLogout() {
        msalInstance.logoutRedirect();
    }

    render() {
        return (
            <Container fluid={true} className={"mt-3"}>
                <Nav className={"mr-auto"}>
                    <NavItem>
                        <NavLink href="/home">Home</NavLink>
                    </NavItem>
                </Nav>
                <span onClick={this.doLogout} className={layoutstyles.logout}>Logout</span>
                {/*<NavbarText><i>{this.props.PageTitle}</i></NavbarText>*/}
            </Container>
        );
    };
}