import React from 'react';
import { Container, Row, Col, Jumbotron, Spinner } from 'reactstrap';

export class LoadingPage extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col xs="12">
                        <br /><br /><br />
                        <Jumbotron className={"text-center"} style={{ backgroundColor: '#FFFFFF' }}>
                            <Spinner type="grow" style={{ color: '#004b4f', width: '3.5rem', height: '3.5rem' }} /><br/><br/>Loading...
                        </Jumbotron>
                    </Col>
                </Row>
            </Container>
        );
    };
}