import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import { MsalProvider } from "@azure/msal-react";
import { CustomNavigationClient } from './msal/CustomNavigationClient';

import { Layout } from './components/layout/Layout';
import { LandingPage } from './components/LandingPage';
import { HomePage } from './components/HomePage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './global.min.css'


export default function App({ msalInstance }) {
    const history = useHistory();
    const navigationClient = new CustomNavigationClient(history);
    msalInstance.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={msalInstance}>
            <Switch>
                <Route exact path="/">
                    <LandingPage msalInstance={msalInstance} />
                </Route>
                <Route path="/home">
                    <Layout componentToPass={HomePage} showNavigation={true} />
                </Route>
            </Switch>
        </MsalProvider>
    );
}